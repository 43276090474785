import Vue from 'vue'
import Dialog from './index.vue'

//创建Dialog构造器
let dialogConstructor = Vue.extend(Dialog)
let instance

const dialog = function(options = {}) {
    //设置默认参数为对象，如果参数为字符串，参数中message属性等于该参数
    if(typeof options === 'string') {
        options = {
            message: options
        }
    }
    options.isShow=true
    options.type=options.type?options.type:'success'
    options.isShowCancel=options.isShowCancel !== undefined
    options.confirmButtonText=options.confirmButtonText || '确定'
    options.cancelButtonText=options.cancelButtonText || '取消'
    //创建实例
    instance = new dialogConstructor({
        data: options
    })
    //删除原有dialog及mask
    let prevDialog=document.querySelector('.van-dialog')
    let prevDialogMask=document.querySelector('.van-overlay')
    prevDialog&&prevDialog.remove()
    prevDialogMask&&prevDialogMask.remove()

    //将实例挂载到body下
    document.body.appendChild(instance.$mount().$el)
}

export default dialog
