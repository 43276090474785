<template>
  <div>
    <van-cell :title="title" class="location" is-link>
      <template #default>
        <div class="cell-value">
          <input type="text" v-model="value" @input="input" :placeholder="placeholder"/>
          <span @click="getAddress">
        <img class="location-icon" src="@/assets/images/icon_dwx.png" alt=""><span style="color:var(--base)">定位</span>
      </span>
        </div>
      </template>
    </van-cell>
    <div></div>
  </div>
</template>

<script>

import eventBus from "@/components/cell/GetAddress2/eventBus";
import wx,{verification} from "@/utils/wxPermissionValidation";
import {getGps} from "@/utils/getGps";

export default {
  name: "GetAddress",
  model: {
    prop: 'address',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      default: '门店地址'
    },
    placeholder: {
      type:String,
      default:'请手动输入或点击选择'
    },
    address: {
      type: String,
      default: ''
    },
    gps:{
      type: Object,
      default(){
        return {
          lng:'',
          lat:''
      }
      }
    }
  },
  watch: {
    address:{
      handler(address) {
        this.value=address
      },
      immediate:true
    },
    gps(gps) {
      this.gps_=gps
    }
  },
  data() {
    return {
      value: '',
      isShowselectAddress: false,
      latitude: '',
      longitude:'',
      isVerified:false,
      gps_:null
    }
  },
  beforeRouteLeave (to, from , next) {
    if (this.isShowselectAddress) {
      this.isShowselectAddress=false
    }else {
      next();
    }
  },
  mounted() {
   eventBus.$on('selectedAddress', this.eventBusHandler)

  },
  beforeDestroy() {
    eventBus.$off('selectedAddress', this.eventBusHandler)

  },
  methods: {
    eventBusHandler(data) {
      let {address,pname,cityname,adname}=data
      let completeAddress=pname+cityname+adname+address
      this.$emit('change', completeAddress)
      this.value=completeAddress
      console.log(`%c data`,'color:red;font-size:20px',data)
    },
    getAddress() {
      if (this.gps_) {
        this.$router.push({path:`/selectAddress/${this.gps_.lng}/${this.gps_.lat}`})
        return
      }
      getGps().then(gps=>{
        this.gps_=gps
        console.log('gps',gps)
        this.$router.push({path:`/selectAddress/${gps.lng}/${gps.lat}`})
      }).catch(()=>{});

    },
    input() {
      this.$emit('change', this.value)

    }
  }
}
</script>

<style scoped lang="less">
.cell-value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
}

.location {
  .van-cell__value {
    flex: 4;
  }

  input {
    text-align: right;
    background: transparent;
    width: 13em;
  }

  .location-icon {
    width: 38px;
    height: 47px;
    position: relative;
  }
}
</style>
