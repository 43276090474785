import myDialog from '@/components/dialog/index'
import AMap from 'maps'

export const getGps=(isShowDialog=true)=>{
    return new Promise((resolve, reject) => {
        if (process.env.NODE_ENV === 'production') {
            navigator.geolocation.getCurrentPosition( ()=> {
                AMap.plugin('AMap.Geolocation', ()=>{
                    let geolocation = new AMap.Geolocation({
                        // 是否使用高精度定位，默认：true
                        enableHighAccuracy: true,
                        // 设置定位超时时间，默认：无穷大
                        timeout: 10000,
                        // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
                        buttonOffset: new AMap.Pixel(10, 20),
                        //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                        zoomToAccuracy: true,
                        //  定位按钮的排放位置,  RB表示右下
                        buttonPosition: 'RB'
                    })

                    let onComplete=data=> {
                        console.log('gpsDate',data)
                        let gps={
                            lng:data.position.lng,
                            lat:data.position.lat,
                            address:data.formattedAddress
                        }

                        resolve(gps)
                    }
                    let onError=(err)=> {
                        this.$toast('获取位置信息失败')
                        console.error(err)
                        reject(err)
                    }
                    geolocation.getCurrentPosition()
                    AMap.event.addListener(geolocation, 'complete', onComplete)
                    AMap.event.addListener(geolocation, 'error', onError)

                })
            }, (err)=> {
                console.error(err)
                if (isShowDialog) {
                    myDialog({
                        type: 'text',
                        title: '提示',
                        message: '请开启GPS定位, 并同意授权',
                        confirmButtonText: '确定',
                        beforeClose(action, done) {
                            done()
                        }
                    });
                }
                reject(err)
            });
        }else {
            resolve({lng:104.064521,lat:30.593728,address:'四川省成都市武侯区桂溪街道益州大道北段16号曙光国际'})
        }

    })

}
