import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        redirect: '/home',
    },
    //用于授权跳转的路由 /auth?path=%2FRepairInfoAdd%2F3&code=001llqll28Otn74X3rll23nz3c4llqlt
    {
        path: '/auth',
        name: 'auth',
        component: () => import('../views/auth')
    },
    {
        path: '/home',
        name: 'Home',
        meta: {
            keepAlive: true
        },
        component: () => import('../views/home')
    },
    {
        path: '/changePhoneInfo',
        name: 'changePhoneInfo',
        meta: {
            title: '焕机信息',
            keepAlive: true
        },
        component: () => import('../views/changePhoneInfo')
    },
    {
        path: '/reservationInfo',
        name: 'reservationInfo',
        meta: {
            keepAlive: true,
            title: '预约信息',
        },
        component: () => import('../views/reservationInfo')
    },
    {
        path: '/orderDetail',
        name: 'orderDetail',
        meta: {
            title: '订单详情',
            keepAlive: false
        },
        component: () => import('../views/orderDetail')
    },
    {
        path: '/Demos',
        name: 'Demos',
        meta: {
            keepAlive: true
        },
        component: () => import('../views/Demos')
    },{
        path: '/SelectAddress/:lng/:lat',    //:lng 经度 :lat 纬度
        name: 'SelectAddress',
        props: true,
        meta: {
            keepAlive: false
        },
        component: () => import('../components/cell/GetAddress2/SelectAddress')
    },{
        path: '/orderInquiry',
        name: 'OrderInquiry',
        meta: {
            title: '订单查询',
            keepAlive: true
        },
        component: () => import('../views/orderInquiry')
    },{
        path: '/orderList',
        name: 'OrderList',
        meta: {
            title: '订单列表',
            keepAlive: true
        },
        component: () => import('../views/orderList')
    },{
        path: '/uploadNewPhoneInfo',
        name: 'uploadNewPhoneInfo',
        meta: {
            title: '新机资料上传',
            keepAlive: true
        },
        component: () => import('../views/uploadNewPhoneInfo')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
