<template>
  <van-cell :title="title" class="scan-id-card">
    <template #default>
      <div class="cell-value">
        <input type="text" v-model="value_" :placeholder="placeholder" @change="change" maxlength="18"/>
        <img class="scan-icon" src="@/assets/images/icon_sys.png" @click="scan" alt="">
      </div>
      <van-uploader
          style="position: fixed;right: -500px"
          ref="uploader"
          v-model="fileList"
          :max-count="1"
          :after-read="afterFileRead"
          :before-delete="beforePicDelete">
      </van-uploader>
    </template>
  </van-cell>
</template>

<script>
import wx,{verification} from "@/utils/wxPermissionValidation";
import axios from "axios";

export default {

  name: "ScanCode",
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: '身份证号'
    },
    placeholder: {
      type: String,
      default: '请输入18位身份证号或扫一扫'
    },
  },
  watch: {
    value: {
      handler(value) {
        this.value_ = value
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      value_: '',
      pic:'',
      fileList:[]
    }
  },
  methods: {
    afterFileRead() {

    },
    beforePicDelete() {

    },
    scan() {
      this.$toast('暂未实现')
      // this.$refs.uploader.chooseFile()
    },
    getIdCardNumber() {
      // let formData = new FormData();
      // formData.append('file', this.fileList[0].file)
      // axios.post('https://ai.ybinsure.com/s/api/ocr/identityCard', formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data'
      //   },
      //   onUploadProgress: progressEvent => {
      //     this.progress = (progressEvent.loaded / progressEvent.total * 100 | 0)
      //   }
      // })
    },
    change() {
      this.$emit('change', this.value_)
    }
  }
}
</script>

<style scoped lang="less">
input {
  text-align: right;
  width: 400px;
}

.scan-icon {
  font-size: 40px;
  color: @base-color;
  position: relative;
  top: 5px;
  font-weight: bold;
  margin-left: 10px;
}

.cell-value {
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.cell-value input {
  flex: 1;
}
.scan-id-card{
  display: flex;
}
.van-cell__title{
  flex: 0 150px;
}
.scan-icon {
  width: 36px;
  height: 36px;
  margin-left: 10px;
}
</style>
