import Vue from "vue";
import './vant'

import MyDialog from '@/components/dialog'
import MyButton from '@/components/Button'
import SelectDiaField from '@/components/cell/SelectDiaField'
//注意事项
import Notice from "@/components/Notice";
//地区选择
import RegionSelector from "@/components/cell/RegionSelector";
//协议checkbox
import Protocol from "@/components/Protocol";
//获取地址
import GetAddress from "@/components/cell/GetAddress2/index";
//获取城市
import GetCity from "@/components/cell/GetCity/index";
//可搜索选择框
import SelectSearchCell from "@/components/cell/SelectSearchCell";
//选择器
import FieldPiker from "@/components/cell/FieldPiker";
//扫码
import ScanCode from "@/components/cell/ScanCode";
//身份证扫描
import ScanIdCardNumber from "@/components/cell/ScanIdCardNumber";
//金额
import Money from "@/components/cell/Money";
//自适应高度textarea
import AdaptiveTextarea from "@/components/cell/AdaptiveTextarea";
//单张图片上传
import PicUploader from "@/components/uploader/PicUploader";
//单张图片上传
import VideoUploader from "@/components/uploader/VideoUploader";
//大标题
import SectionTitle from "@/components/SectionTitle";
//输入框
import CellInput from "@/components/cell/Input";
//图片2x1比例展示
import ImgDisplay2to1 from "@/components/ImgDisplay2to1";
//多选
import MultipleChoice from "@/components/cell/MultipleChoice";
//获取验证码
import ObtainCaptcha from "@/components/cell/ObtainCaptcha";
//预约时间
import ReservationTime from "@/components/cell/ReservationTime";
//开关
import DoubleChoiceButton from "@/components/cell/DoubleChoiceButton";
//带提示的金额展示cell
import MoneyDisplayWithNotice from "@/components/cell/MoneyDisplayWithNotice";
//序列号
import SerialNumber from "@/components/cell/SerialNumber";
//序列号
import MultipleImageUpload from "@/components/uploader/MultipleImageUpload";


//注册自定义组件
Vue.component('MyButton', MyButton)
Vue.component('SelectDiaField', SelectDiaField)
Vue.component('Notice', Notice)
Vue.component('RegionSelector', RegionSelector)
Vue.component('Protocol', Protocol)
Vue.component('GetAddress', GetAddress)
Vue.component('GetCity', GetCity)
Vue.component('SelectSearchCell', SelectSearchCell)
Vue.component('FieldPiker', FieldPiker)
Vue.component('ScanCode', ScanCode)
Vue.component('ScanIdCardNumber', ScanIdCardNumber)
Vue.component('Money', Money)
Vue.component('AdaptiveTextarea', AdaptiveTextarea)
Vue.component('PicUploader', PicUploader)
Vue.component('SectionTitle', SectionTitle)
Vue.component('CellInput', CellInput)
Vue.component('ImgDisplay2to1', ImgDisplay2to1)
Vue.component('VideoUploader', VideoUploader)
Vue.component('MultipleChoice', MultipleChoice)
Vue.component('ObtainCaptcha', ObtainCaptcha)
Vue.component('ReservationTime', ReservationTime)
Vue.component('DoubleChoiceButton', DoubleChoiceButton)
Vue.component('MoneyDisplayWithNotice', MoneyDisplayWithNotice)
Vue.component('SerialNumber', SerialNumber)
Vue.component('MultipleImageUpload', MultipleImageUpload)
Vue.prototype.$myDialog = MyDialog
