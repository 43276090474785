<template>
  <div>
    <van-cell :title="title"
              @click="click"
              is-link>
      <template #default>
        <span v-if="value">{{ value }}</span>
        <span v-else class="placeholder">{{ placeholder }}</span>
      </template>
    </van-cell>
    <div></div>
    <van-popup v-model="isShowPopup" position="bottom">
      <div class="popup-header">
        <span class="cancel" @click="cancel">取消</span>
        <span class="title">{{ label }}</span>
        <span class="confirm" @click="confirm">确定</span>
      </div>
      <div class="fake-notice" v-if="threeDaysLater">
        <van-icon name="warning-o"/>
        提示:{{ threeDaysLater.getDate() }}号前已预约满
      </div>
      <div class="content">
        <div class="left">
          <div class="date" v-for="(date,index) in dates" :key="index" :class="{active:selectedDate===index}"
               @click="selectedDate=index">{{ date }}
          </div>
        </div>
        <div class="right">
          <div class="time" v-for="(time,index) in times" :key="index" :class="{active:selectedTime===index}"
               @click="selectedTime=index">{{ time }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  name: "ReservationTime",
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择预约时间'
    },
    label: {
      type: String,
      default: '选择预约时间'
    }
  },
  data() {
    return {
      isShowPopup: false,
      threeDaysLater: null,
      dates: [],
      selectedDate: null,
      times: [
        '09:00 ~ 12:00',
        '12:00 ~ 15:00',
        '15:00 ~ 18:00',
        '18:00 ~ 19:00',
      ],
      selectedTime: null
    }
  },
  mounted() {
    let threeDaysLater = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 3)
    this.threeDaysLater = threeDaysLater
    threeDaysLater = threeDaysLater.getTime()
    let dates = []
    for (let i = 0; i < 10; i++) {
      let date = new Date(threeDaysLater + 1000 * 60 * 60 * 24 * i)
      let month = date.getMonth() + 1
      let day = date.getDate()
      let week = date.getDay()
      let weeks = ['一', '二', '三', '四', '五', '六', '日']
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      dates.push(`${month}月${day}日(周${weeks[week]})`)
    }
    this.dates = dates

  },
  methods: {
    click() {
      this.isShowPopup = !this.isShowPopup
      if (this.isShowPopup && this.value) {
        let date = this.value.match(/\d{2}月\d{2}日\(.{2}\)/)[0]
        let time = this.value.match(/\d{2}:\d{2} ~ \d{2}:\d{2}/)[0]
        this.selectedDate = this.dates.findIndex(item => (item === date))
        this.selectedTime = this.times.findIndex(item => (item === time))
      }
    },
    cancel() {
      this.isShowPopup = false
    },
    confirm() {
      if (this.selectedDate === null) {
        this.$toast('请选择日期')
        return;
      }
      if (this.selectedTime === null) {
        this.$toast('请选择时间')
        return;
      }
      this.$emit('change', this.dates[this.selectedDate] + ' ' + this.times[this.selectedTime]);
      this.isShowPopup = false
    }
  }
}
</script>

<style scoped lang="less">
@import "~@/assets/style/common.less";

.placeholder {
  color: #999;
}

.popup-header {
  padding: 46px;
  display: flex;
  justify-content: space-between;

  .cancel {
    font-size: 30px;
    font-weight: bold;
    color: #999999;
  }

  .title {
    font-size: 34px;
    font-weight: bold;
    color: #333333;
  }

  .confirm {
    font-size: 30px;
    font-weight: bold;
    color: @base-color;
  }
}

.content {
  display: flex;

  .left {
    box-sizing: border-box;
    border-right: 1px solid #eee;
    width: 280px;
    height: 480px;
    overflow-y: auto;
    border-top: 1px solid #eee;

    .date {
      width: 280px;
      height: 88px;
      line-height: 88px;
      text-align: center;
      background: #FFFFFF;
      font-size: 28px;
      font-weight: 500;
      color: #666666;
      border-bottom: 1px solid #eee;
      border-right: 1px solid #eee;
      white-space: nowrap;

      &.active {
        background: lighten(@base-color, 30%);
        color: @base-color;
      }
    }

  }

  .right {
    width: 470px;
    border-top: 1px solid #eee;

    .time {
      width: 470px;
      height: 88px;
      line-height: 88px;
      text-align: left;
      padding-left: 30px;
      background: #FFFFFF;
      font-size: 28px;
      font-weight: 500;
      color: #666666;
      border-bottom: 1px solid #eee;
      white-space: nowrap;

      &.active {
        color: @base-color;
      }
    }
  }
}

.fake-notice {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: lighten(@base-color, 33%);
  font-size: 24px;
  font-weight: 400;
  color: @base-color;
  padding-left: 25px;
}
</style>
