<template>
  <van-cell :title="title" class="location" v-if="!justIcon">
    <template #default>
      <div class="cell-value">
        <input  v-bind="$attrs" type="text" v-model="value_" :placeholder="placeholder" @change="change"/>

        <img class="scan-icon" src="@/assets/images/icon_sys.png" @click="scan" alt="">
      </div>
    </template>
  </van-cell>
  <img v-else class="icon" src="@/assets/images/home/icon_sm.png" @click="scan" alt="">

</template>

<script>
import jsQR from 'jsqr'
export default {

  name: "ScanCode",
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'IMEI号'
    },
    placeholder: {
      type: String,
      default: '请手动输入或扫码IMEI号'
    },
    justIcon: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      handler(value) {
        this.value_ = value
      },
      deep: true,
      immediate: true
    },
    value_(value_) {
      this.$emit('change', value_)
    }
  },
  data() {
    return {
      value_: ''
    }
  },
  methods: {
    scan() {
      // console.log( navigator)
      // const code = jsQR(imageData, width, height, options?)
      // navigator.mediaDevices.getUserMedia({ audio: true, video: true })
      //     .then(function(stream) {
      //       console.log(`%c stream`,'color:red;font-size:20px',)
      //     })
      //     .catch(function(err) {
      //       console.log(`%c err`,'color:red;font-size:20px',err)
      //     });
    },
    change() {
      this.$emit('change', this.value_)
    }
  }
}
</script>

<style scoped lang="less">
input {
  text-align: right;
  width: 380px;
}

.scan-icon {
  width: 36px;
  height: 36px;
  margin-left: 10px;
}
.icon{
  width: 46px;
  height: 46px;
}
.cell-value {
  white-space: nowrap;
}
.cell-value {
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.cell-value input {
  flex: 1;
}
.location{
  display: flex;
}
.van-cell__title{
  flex: 0 150px;
}
</style>
