<template>
  <div>
    <van-field
        class="form_wid"
        readonly
        clickable
        :label-width="labelWidth"
        :label="label"
        :value="typeof selectValue==='object'?selectValue[valueKey]:selectValue"
        is-link
        input-align='right'
        :placeholder="placeholder"
        :disabled="disabled"
        @click="clickDiaShow"
    >
      <template #right-icon>
        <van-icon class="iconfont" class-prefix="icon" name="xiala" size="18" color="#CCCCCCC"/>
      </template>
    </van-field>

    <div class="added">
      <div class="notice" v-if="$slots.default">
        <div class="notice-content">
          <img class="warning-icon" src="@/assets/images/warning.png" alt="">
          <div><slot></slot></div>
        </div>
      </div>
      <van-popup v-model="show" position="bottom">
        <van-picker
            :title="title"
            show-toolbar
            :columns="columns"
            :value-key="valueKey"
            @cancel="show = !show"
            :default-index="defaultIndex(selectValue)"
            @confirm="onConfirm"
        />
      </van-popup>
    </div>
  </div>
</template>
<script>
export default {
  name: 'fieldPiker',
  model: {
    prop: 'selectValue',
    event: 'getMessage'
  },
  props: {
    columns: {
      type: Array
    },
    title: {
      type: String
    },
    selectValue: {
      type: [String,Object,Number]
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    valueKey: {
      type: String
    },
    disabled:{
      type:Boolean,
      default:false
    },
    labelWidth:{
      type: String,
      default: ''
    },
    toastWithoutData: {
      type: String,
      default: '暂无数据'
    },
  },
  mounted() {
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    defaultIndex() {
      return value => {
        return this.columns.findIndex(item => {
          return this.valueKey ? item[this.valueKey] === value[this.valueKey]:item===value
        })
      }
    }
  },
  methods: {
    onConfirm(value) {
      this.value = value&&value.valueKey||'';
      this.show = !this.show;
      this.$emit("getMessage", value);
    },
    clickDiaShow() {
      if (this.disabled) {
        return
      }
      if (!this.columns.length) {
        this.$toast(this.toastWithoutData)
        return;
      }
      this.show = !this.show;
      this.$emit("clickDiaShow", this.show);
    }
  },
  watch: {}
};
</script>

<style scoped lang="less">
.form_wid {
  .van-cell {
    padding-left: 0;
  }
}
::v-deep .van-picker__confirm {
  color: @base-color;
}
::v-deep .van-picker__title {
  font-weight: bold;
}

.notice {
  width: 100%;
  background: #fff;
}
.van-cell::after{
  display: none;
}
.added {
  position: relative;
  background: #fff;
}
.added:after{
  position: absolute;
  box-sizing: border-box;
  content: ' ';
  pointer-events: none;
  right: 0.42667rem;
  bottom: 0;
  left: 0.42667rem;
  border-bottom: 0.02667rem solid #ebedf0;
  transform: scaleY(0.5);
}
.notice {
  background: #fff;
  width: 100%;
  position: relative;
  top: -40px;
}
.notice-content{
  width: calc(100vw - 80px);
  background: #FEF6F6;
  border-radius: 14px;
  font-size: 20px;
  font-weight: 400;
  color: #FF4A4A;
  line-height: 30px;
  margin: 0 auto;
  padding: 10px;
  display: flex;
}

.warning-icon {
  width: 19px;
  height: 19px;
  margin-right: 5px;
  margin-top: 4px;
}
</style>
