<template>
    <div>
        <!-- select选择框文本对应的id -->
        <van-field
            v-model="nameId"
            :name="$attrs.nameId"
            type="hidden"
            v-show="false"
        />
        <!-- select选择框文本 -->
        <van-field
            v-model="result"
            v-bind="$attrs"
            readonly
            :name="name"
            is-link
            input-align='right'
            @click="show = !show"
        >
            <template #right-icon>
                <van-icon class="iconfont" class-prefix="icon" name="xiala" size="18" color="#CCCCCCC"/>
            </template>
        </van-field>
      <div></div>
        <van-popup v-model="show" position="bottom">
            <van-picker
                    :columns="columns"
                    show-toolbar
                    :title="$attrs.label"
                    @cancel="show = !show"
                    @confirm="onConfirm"
            />
        </van-popup>
    </div>
</template>

<script>
    export default {
        name:'SelectDiaField',
        model: {
            prop: "selectValue"
        },
        props: {
            columns: {
                type: Array
            },
            selectValue: {
                type: String
            },
            name:{
                type: String  //父组件传进来一个name
            }
        },
        data() {
            return {
                show: false,
                result: this.selectValue,
                nameId:''
            };
        },
        methods: {
            onConfirm(value) {
                //value.text  value.keyId
                this.result = value.text;
                this.nameId = value.keyId;
                this.show = !this.show;
                this.$emit("getMessage", this.name,value.keyId);//把value值传给父组件
            },
        },
        watch: {
            selectValue: function(newVal) {
                this.result = newVal;
            },
            result(newVal) {
                this.$emit("input", newVal);
            }
        }
    };
</script>
<style></style>
