<template>
  <van-dialog
      v-model="isShow"
      :message="message"
      theme="round-button"
      :show-cancel-button="isShowCancel"
      :before-close="beforeClose"
      :confirm-button-text="confirmButtonText"
      :cancel-button-text="cancelButtonText"
      :close-on-click-overlay="closeOnClickOverlay">
    <div class="content">
      <img class="icon" v-if="type!=='text'" :src="require(`@/assets/images/${type}.png`)" alt="">
      <div class="title">{{ title }}</div>
      <div class="message" v-html="message"></div>
    </div>
  </van-dialog>
</template>

<script>
export default {
  name: "MyDialog",
  data() {
    return {
      //类型 success/error
      type: 'success',
      isShow: true,
      title: '',
      message: '',
      // 关闭前的回调函数，
      // 参数action: confirm点击确定按钮/cancel点击取消按钮
      // 参数done 调用done() 后关闭弹窗,调用 done(false) 阻止弹窗关闭
      beforeClose: null,
      //是否显示取消按钮
      isShowCancel: false,
      confirmButtonText:'确定',
      cancelButtonText:'取消',
      closeOnClickOverlay:true
    }
  },
  mounted() {

  }
}
</script>

<style scoped lang="less">
.icon {
  width: 112px;
  height: 112px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 47px 65px 26px;

  .icon {
    margin-bottom: 31px;
  }

  .title {
    color: #323234;
    margin-bottom: 36px;
    font-weight: bold;
  }

  .message {
    color: #706F7E;
    line-height: 44px;
    font-size: 28px;
    text-align: center;
    max-width: 100%;
  }
}

::v-deep {
  .van-dialog--round-button .van-dialog__footer {
    padding-left: 90px;
    padding-right: 90px;
  }

  .van-dialog__confirm {
    background: @base-color;
    box-shadow: 0 6px 15px 1px rgba(87, 123, 181, 0.3);
    //margin: 0 66px;
  }

  .van-dialog__cancel {
    background: #F1EFF0;
    box-shadow: 0 6px 15px 1px rgba(139, 138, 138, 0.3);
    //margin: 0 66px;
    color: #999;
  }
}
::v-deep .van-goods-action-button{
  border-radius: 100px;
  width: 100px;
}
::v-deep .van-goods-action-button:nth-child(2) {
  margin-left: 50px;
}
.message {
  max-height: 60vh;
  overflow-y: auto;
}
</style>
