import request from "@/utils/http";

export function getRegion(params = {}) {
    return request({
        url: '/index/getRegion',
        method: 'post',
        data: params
    })
}

export function homeInfo(params = {}) {
    return request({
        url: '/Activity/home',
        method: 'post',
        data: params
    })
}

export function getWxsdk(params = {}) {
    return request({
        url: '/Index/getWxsdk',
        method: 'post',
        data: params,
        isLoading: false
    })
}

export function imageUp(file) {
    let data = new FormData();
    data.append('file', file)
    return request({
        url: '/Uploadify/imageUp',
        method: 'post',
        data
    })
}

export function phonePriceModel(params = {}) {
    return request({
        url: '/Activity/phonePriceModel',
        method: 'post',
        data: params
    })
}
export function phonePriceModel2(params = {}) {
    return request({
        url: '/Activity/phonePriceModel2',
        method: 'post',
        data: params
    })
}

export function phonePriceRam(params = {}) {
    return request({
        url: '/Activity/phonePriceRam',
        method: 'post',
        data: params
    })
}

export function phoneAfficialPrice(params = {}) {
    return request({
        url: '/Activity/phoneAfficialPrice',
        method: 'post',
        data: params,
        isLoading: false,
        toast:false
    })
}
export function phonePriceColour(params = {}) {
    return request({
        url: '/Activity/phonePriceColour',
        method: 'post',
        data: params
    })
}
export function phoneKsii(params = {}) {
    return request({
        url: '/Activity/phoneKsii',
        method: 'post',
        data: params
    })
}

export function setOrderActivity(params = {}) {
    return request({
        url: '/Activity/setOrderActivity',
        method: 'post',
        data: params
    })
}
export function getStores(params = {}) {
    return request({
        url: '/Activity/getStores',
        method: 'post',
        data: params
    })
}

export function sendSms(params = {}) {
    return request({
        url: '/Activity/sendSms',
        method: 'post',
        data: params
    })
}
export function completeOrder(params = {}) {
    return request({
        url: '/Activity/completeOrder',
        method: 'post',
        data: params
    })
}
export function checkSn(params = {}) {
    return request({
        url: '/Activity/checkSn',
        method: 'post',
        data: params,
        loadingText:'正在查询，请稍后...'
    })
}
export function orderActivityInfo(params = {}) {
    return request({
        url: '/Activity/orderActivityInfo',
        method: 'post',
        data: params
    })
}
export function checkChA(params = {}) {
    return request({
        url: '/Activity/checkChA',
        method: 'post',
        data: params,
        loadingText:'正在查询，请稍后...'
    })
}
