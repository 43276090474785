import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //屏幕的缩放比
    scale:1,
    openid: '',
    userInfo: {},
    config:null,
    //     {
    //   is_sms:1,
    //   pass_id:1
    // },
    phoneInfo: {
      oldModel: '',
      newModel: '',
      oldRAM: '',
      newRAM: '',
      oldColor: '',
      newColor: '',
      oldMoney: '',
      newMoney: '',
      money: '',
      deduct_price: '',
      diff_money: '',
    },
    orderList:[],
    orderDetail:null,
    storeData:{
      isManual:false,
      currentStore:null
    }
  },
  mutations: {
    setScale(state,scale) {
      state.scale=scale
    },
    setOpenid(state,openid) {
      state.openid=openid
    },
    setUserInfo(state, val) {
      state.userInfo=val
    },
    setConfig(state, val) {
      state.config=val
    },
    setPhoneInfo(state, val) {
      state.phoneInfo=val
    },
    setOrderDetail(state, val) {
      state.orderDetail=val
    },
    setStoreData(state, val) {
      state.storeData=val
    },
    setOrderList(state, val) {
      state.orderList=val
    },
  },
  actions: {
  },
  modules: {
  }
})
