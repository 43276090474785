<template>
  <div class="money-width-notice">
    <div class="input-wrapper">
      <div class="title">{{title}}</div>
      <input v-bind="$attrs" type="number" class="cell-input" :style="{color}"   v-model="value" :placeholder="placeholder" disabled>
    </div>
    <div class="notice" v-if="notice">
      {{ notice }}
    </div>
  </div>
</template>

<script>
export default {
  name: "MoneyDisplayWithNotice",
  model: {
    prop: 'money',
    event: 'change'
  },
  props: {
    money: {
      type: [String,Number],
      default: ''
    },
    title: {
      type: String,
      default: '焕新价格'
    },
    placeholder: {
      type: String,
      default: '系统计算生成'
    },
    color:{
      type:String,
      default:'#ee0a24'
    },
    notice:{
      type:String,
      default:''
    },
  },
  data() {
    return {
      value: ''
    }
  },
  watch: {
    money: {
      handler(value) {
        this.value = value
      },
      immediate: true
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
.cell-input {
  text-align: right;
  background: #fff;
  color: #999;
}
.money-width-notice{
  background-color: #fff;
  padding: 24px 28px;
  position: relative;
}
.money-width-notice:after{
  position: absolute;
  box-sizing: border-box;
  content: '';
  pointer-events: none;
  right: 30px;
  bottom: 0;
  left: 30px;
  border-bottom: 1px solid #ebedf0;
  transform: scaleY(0.5);
}
.input-wrapper {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  color: #666;
  font-size: 28px;
  line-height: 55px;
  background-color: #fff;
  opacity: 0.98;
  align-items: flex-start;
  justify-content: space-between;
}
.notice {
  width: 100%;
  background: #F6F6F6;
  border-radius: 10px;
  font-size: 24px;
  font-weight: 400;
  color: #999999;
  padding: 12px;
}

</style>
