<template>
  <div id="app">
    <keep-alive>
      <router-view v-if='$route.meta.keepAlive'/>
    </keep-alive>
    <router-view v-if='!$route.meta.keepAlive'/>

  </div>
</template>

<script>
export default ({
  name: 'app',
  created() {
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
          Object.assign(
              {},
              this.$store.state,
              JSON.parse(sessionStorage.getItem("store"))
          )
      );
    }
//在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      if (this.$route.path === '/home') {
        sessionStorage.clear()
      }else {
        sessionStorage.setItem("store", JSON.stringify(this.$store.state));
      }
    });

    this.$store.commit('setScale', parseFloat(document.documentElement.style.fontSize) / 75)


  },
  methods: {}
})
</script>
<style lang="less">
#app {
  font-size: 32px;

  * {
    box-sizing: border-box;
  }
}

</style>
