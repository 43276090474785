<template>
  <van-image fit="cover" class="img" @click="$imagePreview([src])" :src="src" alt=""></van-image>
</template>

<script>
export default {
  name: "ImgDisplay2to1",
  props:{
    src:{
      type:String,
      default:''
    }
  }
}
</script>

<style scoped>
.img {
  width: calc(100vw - 60px);
  height: 345px;
  border-radius: 16px;
  overflow: hidden;
}
</style>
