<template>
  <div class="adaptive-textarea">
    <textarea :placeholder="placeholder" ref="textarea" v-model="value_" @input="input" :maxlength="maxLength"></textarea>
    <div class="count">{{value_.length}}/{{maxLength}}</div>
  </div>
</template>

<script>
export default {
  name: "AdaptiveTextarea",
  model: {
    prop:'value',
    event:'change'
  },
  props: {
    placeholder: {
      type: String,
      default: '请在此输入内容'
    },
    value:{
      type:String,
      default: ''
    },
    maxLength:{
      type:Number,
      default: 200
    }
  },
  data() {
    return{
      value_:'',
      minHeight:''
    }
  },
  watch: {
    value:{
      handler(val) {
        this.value_=val
        this.$nextTick(()=>{
          this.adjustHeight();
        })
      },
      immediate:true
    }
  },
  mounted() {
    let textarea = this.$refs.textarea;
    textarea.style.boxSizing = textarea.style.mozBoxSizing = 'border-box';
    textarea.style.overflowY = 'hidden';
    this.minHeight=textarea.scrollHeight

    let resizeHandler=()=>{
      this.adjustHeight();
    }
    window.addEventListener('resize', resizeHandler);
    this.$on('hook:beforeDestroy',()=>{
      window.removeEventListener('resize',resizeHandler)
    })
    
    this.adjustHeight();
  },
  methods: {
    adjustHeight() {
      let textarea = this.$refs.textarea;
      let minHeight = this.minHeight;

      let outerHeight = parseInt(window.getComputedStyle(textarea).height, 10);
      let diff = outerHeight - textarea.clientHeight;
      textarea.style.height = 0;
      textarea.style.height = Math.max(minHeight, textarea.scrollHeight + diff) + 'px';
    },
    input() {
      this.adjustHeight();
      this.$emit('change',this.value_)
    }
  }
}
</script>

<style scoped lang="less">
.adaptive-textarea {
  position: relative;

}
.count {
  position: absolute;
  right: 5px;
  bottom: 10px;
  color: #999;
  font-size: 24px;
  transform: scale(0.8);
}
textarea {
  background: #F6F8FE;
  width: 100%;
  border-radius: 16px;
  color: #999999;
  font-size: 28px;
  padding: 24px;
  resize: none;
}
</style>