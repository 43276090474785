<template>
  <van-cell :title="title">
    <template #default>
      <input v-bind="$attrs" class="input" v-model="value_" @input="input">
    </template>
    <template #title v-if="!title">
      <slot name="title"></slot>
    </template>
  </van-cell>
</template>

<script>
export default {
  name: "InputWidthNotice",
  model: {
    prop:'value',
    event:'change'
  },
  props: {
    title: {
      type: String,
      default:''
    },
    value: {
      type: String,
      default:''
    }
  },
  data() {
    return {
      value_:''
    }
  },
  watch: {
    value:{
      handler(value) {
        this.value_=value
      },
      immediate:true
    }
  },
  methods:{
    input() {
      if (this.$attrs.type === 'tel' || this.$attrs.type === 'phone') {
        this.value_ = this.value_.replace(/\D/g, '').slice(0,11)
      }
      this.$emit('change',this.value_)
    }
  }
}
</script>

<style scoped>
.input{
  text-align: right;
}
</style>
