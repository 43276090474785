<template>
  <div class="multiple-image-upload">
    <div class="header">
      <div class="title">{{ title }}</div>
      <div class="subtitle">（最多支持上传{{ maxSize }}张照片）</div>
    </div>
    <div class="content">
      <div class="img-wrapper uploader" v-if="fileList.length<maxSize">
        <van-uploader
            :max-count="maxSize"
            :preview-image="false"
            multiple
            accept="image/*"
            :file-list="fileList"
            :after-read="getImgFile"
        >
          <img class="img-display uploader-icon" src="@/assets/images/icon_sctp.png" alt="">
        </van-uploader>
      </div>
      <div class="img-wrapper" v-for="(item,index) in fileList" :key="index" ref="img" @click="$imagePreview([item])">
        <img class="img-display" :src="item" alt="">
        <img class="delete-icon" src="@/assets/images/icon_gb.png" alt="" @click.stop="deleteImg(index)">
      </div>


      <div class="img-wrapper example"  @click="$imagePreview([require('@/assets/images/examplePic/12312.png')])">
        <img class="img-display" src="@/assets/images/examplePic/12312.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
// import {compression} from "@/components/uploader/imageCompression";
import {imageUp} from "@/api";
import {compression} from "@/components/uploader/imageCompression";

export default {
  name: "MultipleImageUpload",
  props: {
    title: {
      type: String,
      default: "新机照片"
    },
    maxSize: {
      type: Number,
      default: 6
    },
    fileList:{
      type: Array,
      default() {
        return[]
      }
    }
  },
  data() {
    return{
      isMove:false
    }
  },
  mounted() {

  },
  methods:{
    getImgFile(e) {
      let nextIndex=this.fileList.length
      if (nextIndex >=this.maxSize) {
        return
      }

      if (Array.isArray(e)) {
        for (let i = 0; i < e.length; i++) {
          this.upload(e[i].file,nextIndex+i)
        }
      }else if (e && e.file) {
        this.upload(e.file,nextIndex);
      }
    },
    upload(file,index) {
      compression(file, 'aaa', (compressedPicture) => {
        console.log(compressedPicture)

      });
      imageUp(file).then(res => {
        this.$emit('change', res.data.url,index)
      }).catch(err => {
        console.log(err)
      })
    },
    deleteImg(index) {
      this.$emit('change', '',index)
    }
  }
}
</script>

<style scoped>
*{
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}
.multiple-image-upload {
  padding: 0 40px;
  background: #fff;
}

.header {
  width: calc(100vw - 80px);
  height: 80px;
  line-height: 80px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}

.header:after {
  position: absolute;
  box-sizing: border-box;
  content: ' ';
  pointer-events: none;
  right: 40px;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #ebedf0;
  transform: scaleY(0.5);
  width: 100%;
}

.title {
  font-size: 28px;
  font-weight: 500;
  color: #333333;
}

.subtitle {
  font-size: 24px;
  font-weight: 400;
  color: #999999;
}

.content {
  display: flex;
  flex-wrap: wrap;
}

.img-wrapper {
  width: calc((100vw - 120px) / 3);
  height: calc((100vw - 120px) / 3);
  margin-right: 20px;
  margin-bottom: 20px;
  background: #EDEDED;
  border-radius: 20px;
  position: relative;
}
.img-wrapper:nth-child(3n) {
  margin-right: 0;
}
.example.example:after {
  content: "示例";
  display: block;
  position: absolute;
  padding: 5px 15px;
  background: #FF4A4A;
  top:0;
  right: 0;
  font-size: 18px;
  color: #fff;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  letter-spacing: 5px;
  opacity: 0.9;
}
.img-display {
  width: calc((100vw - 120px) / 3);
  height: calc((100vw - 120px) / 3);
  border-radius: 20px;
  pointer-events: none;
}
.delete-icon {
  position: absolute;
  width: 32px;
  height: 32px;
  top: -12px;
  right: -12px;
}
</style>
