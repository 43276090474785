export const decodeHTML = function(str){
    if (!str) {
        return ''
    }
    return str.replace(/&lt;|&gt;|&quot;|&#39;|&amp;|&ensp;|&emsp;/g, match => {
        switch (match) {
            case "&lt;":
                return "<";
            case "&gt;":
                return ">";
            case "&quot;":
                return "\"";
            case "&#39;":
                return "'";
            case "&amp;":
                return "&";
            case "&ensp;":
                return " ";
            case "&emsp;":
                return "　";
            default:
                break;
        }
    });
}
