<template>
  <van-cell :title="title">
    <template #title v-if="!title">
      <slot name="title"></slot>
    </template>
    <template #default>
      <div class="value">
        <input :placeholder="placeholder" class="input" v-model="value_" @input="input">
        <div v-if="isNeedVerify" class="button" :class="{disabled}" @click="inquiry">查询</div>
      </div>
    </template>
  </van-cell>
</template>

<script>
export default {
  name: "SerialNumber",
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      default: '序列号'
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isNeedVerify: {
      type: [Boolean,Number],
      default: true
    }
  },
  data() {
    return {
      value_: ''
    }
  },
  watch: {
    value: {
      handler(value) {
        this.value_ = value
      },
      immediate: true
    }
  },
  methods: {
    input() {
      if (this.$attrs.type === 'tel' || this.$attrs.type === 'phone') {
        this.value_ = this.value_.replace(/\D/g, '').slice(0, 11)
      }
      this.$emit('change', this.value_)
    },
    inquiry() {
      if (this.disabled) {
        return
      }
      this.$emit('inquiry');
    }
  }
}
</script>

<style scoped lang="less">

.value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.input {
  text-align: right;
  width: 300px;
  margin-right: 20px;
}

.button {
  width: 120px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: @base-color;
  border: 1px solid @base-color;
  border-radius: 30px;
  font-size: 24px;
  font-weight: 500;
  color: #FFFFFF;
  &.disabled{
    background: lighten(@base-color,20%);
    border-color: lighten(@base-color,20%);
  }
}
</style>
