<template>
  <van-cell :title="title">
    <template #default>
      <input v-bind="$attrs" type="number" class="cell-input" :style="{color}" @input="input" @blur="blur" v-model="value" :placeholder="placeholder">
    </template>
  </van-cell>
</template>

<script>
export default {
  name: "Money",
  model: {
    prop: 'money',
    event: 'change'
  },
  props: {
    money: {
      type: [String,Number],
      default: ''
    },
    title: {
      type: String,
      default: '维修金额'
    },
    placeholder: {
      type: String,
      default: '请输入维修金额'
    },
    color:{
      type:String,
      default:'#ee0a24'
    },
  },
  data() {
    return {
      value: ''
    }
  },
  watch: {
    money: {
      handler(value) {
        this.value = value
      },
      immediate: true
    }
  },
  methods: {
    input() {
      this.value= this.value .replace(/[^0-9.]/g, '')   //非0-9.
          .replace(/(^0+)([^.]+)/g,($1,$2,$3)=>($3)) //开头的0
          .replace(/\.+/g, '.')   //连续的小数点
          .replace(/^\./, '0.')   //.开头转换成0.
          .replace(/(\d+\.\d{1,2}).*/, ($1, $2) => ($2))  //小数点后保留两位

      this.$emit('change', this.value)

    },
    blur() {
      this.value=parseFloat(parseFloat(this.value||0).toFixed(2))

      this.$emit('change', this.value)
    },
  }
}
</script>

<style scoped lang="less">
.cell-input {
  text-align: right;
  background: #fff;
  color: #999;
}

</style>
