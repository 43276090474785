<template>
  <div class="section-title">
    {{ title }}
    <slot></slot>
    <span class="star" v-if="showAsterisk">*</span></div>
</template>

<script>
export default {
  name: "SectionTitle",
  props:{
    title:{
      type:String,
      value: ''
    },
    showAsterisk:{
      type:Boolean||String,
      value: false
    },
  }
}
</script>

<style scoped lang="less">
.section-title {
  padding-left: 24px;
  padding-top: 30px;
  margin-bottom: 20px;
  font-weight: 800;
  font-size: 32px;
  color: #333333;
}

.star {
  color: @red;
  position: relative;
  left: 5px;
  top: 5px;
}
</style>
