<template>
  <van-cell :title="title">
    <template #default>
      <div class="cell-value">
        <input type="number" class="input" v-bind="$attrs" @input="input" ref="input">
        <van-button class="button" type="danger" round size="small" @click="getCaptcha" :disabled="!!countdown">
          {{ countdown ? countdown + 's' : '获取验证码' }}
        </van-button>
      </div>
    </template>
    <template #title v-if="!title">
      <slot name="title"></slot>
    </template>
  </van-cell>
</template>

<script>
import {sendSms} from "@/api";

export default {
  name: "ObtainCaptcha",
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    captcha: {
      type: String,
      default: ''
    },
    phone: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      countdown: 0,
      interval: null,
      alreadySent: false
    }
  },
  watch: {
    phone: {
      handler() {
        clearInterval(this.interval);
        this.countdown = 0
        this.$emit('input', '')
        this.alreadySent = false
      },
      immediate: true
    }
  },
  methods: {
    getCaptcha() {
      if (!/^1[3-9]\d{9}$/.test(this.phone)) {
        this.$toast('请输入正确手机号码');
        return
      }
      this.alreadySent = true
      this.countdown = 60;
      this.interval = setInterval(() => {
        this.countdown = this.countdown - 1
        if (this.countdown <= 0) {
          clearInterval(this.interval)
        }
      }, 1000)

      sendSms({phone:this.phone}).then(res=>{
        this.$emit('sent')
        this.$toast(res&&res.msg||'发送验证码成功，请注意查收')
      }).catch(err=>{
        this.$toast(err&&err.msg||'发送验证码失败')
      })
    },
    input() {
      if (!this.alreadySent) {
        this.$toast('请先获取验证码')
        this.$refs.input.value=''
        return
      }
      this.$emit('input', this.$refs.input.value);
    }
  }
}
</script>

<style scoped>
.input {
  text-align: right;
  width: 200px;
  margin-right: 20px;
}

.button {
  width: 160px;
}

.cell-value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
