//vant 按需引入
import Vue from 'vue';
import {
    Button,
    calendar,
    Cascader,
    Cell,
    CellGroup,
    DatetimePicker,
    Dialog,
    Field,
    Form,
    Icon,
    List,
    Overlay,
    Picker,
    Popup,
    Search,
    Toast,
    Uploader,
    ImagePreview,
    Image,
    Switch
} from 'vant';


Vue.use(Dialog);
Vue.use(Uploader);
Vue.use(Form);
Vue.use(Icon);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(CellGroup);
Vue.use(Cell);
Vue.use(Field);
Vue.use(Button);
Vue.use(Overlay);
Vue.use(Toast);
Vue.use(Cascader );
Vue.use(List);
Vue.use(Search);
Vue.use(DatetimePicker);
Vue.use(calendar);
Vue.use(Image)
Vue.use(Switch)
// Vue.use(AddressEdit)
// Vue.use(Area)
// Vue.use(Calendar)
// Vue.use(Checkbox)
// Vue.use(CheckboxGroup)
// Vue.use(CollapseItem)
// Vue.use(CountDown)
// Vue.use(DropdownItem)
// Vue.use(ImagePreview)
// Vue.use(IndexBar)
// Vue.use(Lazyload)
// Vue.use(Locale)
// Vue.use(Notify)
// Vue.use(Progress)
// Vue.use(Sku)
// Vue.use(Swipe)
// Vue.use(SwipeCell)
// Vue.use(Tabs)
Vue.prototype.$imagePreview=ImagePreview