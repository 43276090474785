<template>
  <van-cell :title="title">
    <template #title v-if="!title">
      <slot name="title"></slot>
    </template>
    <template #default>
      <div class="wrapper">
        <div class="choice-one" @click="choose(0)" :class="{active:value[valueKey]===choices[0][valueKey],'only-one':!choices[1]}">{{choices[0][labelKey]}}</div>
        <div class="choice-two" v-if="choices[1]" @click="choose(1)" :class="{active:value[valueKey]===choices[1][valueKey]}">{{choices[1][labelKey]}}</div>
      </div>
    </template>

  </van-cell>
</template>

<script>
export default {
  name: "DoubleChoiceButton",
  model: {
    prop:'value',
    event:'change'
  },
  props: {
    title: {
      type: String,
      default:''
    },
    value: {
      type: [String,Object],
      default:''
    },
    choices:{
      type: Array,
      default() {
        return []
            // [
            //   {name: '快递上门', id: 2,disabled:false,text:'抱歉，该门店暂不支持快递上门服务'},
            //   {name: '到店焕新', id: 1,disabled:true,text:'抱歉，该门店暂不支持到店焕新服务'},
            // ]
      }
    },
    labelKey: {
      type: String,
      default:'name'
    },
    valueKey: {
      type: String,
      default:'name'
    },
  },
  data() {
    return {
      currentIndex:null
    }
  },
  mounted() {
    if (this.choices.length === 1) {
      this.currentIndex=0
      this.$emit('change',this.choices[0])
    }
  },
  methods:{
    choose(index) {
      let item=this.choices[index]
      if (item.disabled) {
        this.$toast(item.text||'赞不支持')
        return
      }
      this.currentIndex=index;
      this.$emit('change',item)
    }
  }
}
</script>

<style scoped lang="less">

::v-deep .van-cell__value {
  position: relative;
}
.wrapper {
  display: flex;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 20px;
}
.choice-one,.choice-two {
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  background: #F6F6F6;
  border: 1px solid #BDBDBD;
  font-size: 24px;
  font-weight: 500;
  color: #666666;
}
.choice-one {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-right: none;
}
.choice-one.only-one {
  border-radius: 30px;
  border: 1px solid @base-color;
}

.choice-two {
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}
.active {
  background: @base-color;
  color: #fff;
  border-color: @base-color;
}
.choice-one.active +.choice-two{
  border-left: none;
}
</style>
